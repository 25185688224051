import React, { useEffect, useState } from "react";
import "./schedule.scss";
import { TeambookCheckbox } from "../TeambookCheckbox";
import { DateTime } from "luxon";
import { TimeField } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

const parseMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

const luxonTime = (minutes) => {
  return DateTime.fromISO(`2000-01-01T${parseMinutesToTime(minutes)}:00`);
};

const manageableKinds = ["create", "edit"];

const ScheduleDay = ({ index, schedule, setSchedule, kind }) => {
  const { t } = useTranslation();
  const dayInfo = schedule[index];
  const [startTime, setStartTime] = useState(luxonTime(dayInfo[3]));
  const [lunchStartTime, setLunchStartTime] = useState(luxonTime(dayInfo[0] + dayInfo[3]));
  const [lunchEndTime, setLunchEndTime] = useState(luxonTime(dayInfo[0] + dayInfo[1] + dayInfo[3]));
  const [endWorkTime, setEndWorkTime] = useState(luxonTime(dayInfo[0] + dayInfo[1] + dayInfo[2] + dayInfo[3]));
  const [isActive, setIsActive] = useState(dayInfo[0] + dayInfo[2] > 0);

  const daysOfWeek = [
    t("my_week.sunday"),
    t("my_week.monday"),
    t("my_week.tuesday"),
    t("my_week.wednesday"),
    t("my_week.thursday"),
    t("my_week.friday"),
    t("my_week.saturday"),
  ];

  useEffect(() => {
    if (!scheduleIsEditable()) return;

    setSchedule((oldSchedule) => {
      let newSchedule = oldSchedule;
      newSchedule[index] = [
        lunchStartTime.diff(startTime, "minutes").toObject().minutes,
        lunchEndTime.diff(lunchStartTime, "minutes").toObject().minutes,
        endWorkTime.diff(lunchEndTime, "minutes").toObject().minutes,
        startTime.hour * 60 + startTime.minute,
      ];

      return newSchedule;
    });
  }, [startTime, lunchStartTime, lunchEndTime, endWorkTime, kind]);

  const scheduleIsEditable = () => {
    return manageableKinds.includes(kind);
  };

  const handleCheckboxChange = () => {
    if (!scheduleIsEditable()) return;

    let updatedDay;

    if (!isActive) {
      updatedDay = [240, 60, 240, 540];
      setLunchStartTime(luxonTime(780));
      setLunchEndTime(luxonTime(840));
      setEndWorkTime(luxonTime(1080));
      setStartTime(luxonTime(540));
    } else {
      updatedDay = [0, 60, 0, 540];
      setStartTime(luxonTime(0));
      setLunchStartTime(luxonTime(0));
      setLunchEndTime(luxonTime(0));
      setEndWorkTime(luxonTime(0));
    }

    setIsActive(!isActive);
    setSchedule((oldSchedule) => {
      let newSchedule = oldSchedule;
      newSchedule[index] = updatedDay;
      return newSchedule;
    });
  };

  const calculateDuration = () => {
    const totalWorkTime = endWorkTime.diff(startTime, "minutes").toObject().minutes;
    const netWorkTime = totalWorkTime - lunchEndTime.diff(lunchStartTime, "minutes").toObject().minutes;

    const hours = netWorkTime / 60;
    const roundedHours = Math.round(hours * 2) / 2;

    return `${roundedHours}h`;
  };

  const handleTimeChange = (newValue, setSelectedTime) => {
    if (!scheduleIsEditable()) return;

    if (newValue) {
      const minutes = newValue.minute;
      let adjustedMinutes = minutes;

      if (minutes === 3) {
        adjustedMinutes = 30;
      } else if (minutes < 15) {
        adjustedMinutes = 0;
      } else if (minutes < 45) {
        adjustedMinutes = 30;
      } else {
        adjustedMinutes = 0;
        newValue.plus({ hour: 1 });
      }

      setSelectedTime(newValue.set({ minute: adjustedMinutes }));
    }
  };

  return (
    <div className="schedule__day">
      <div className="schedule__day-checkbox">
        <TeambookCheckbox checked={isActive} onChange={handleCheckboxChange} />
        <p>{daysOfWeek[index]}</p>
      </div>

      {isActive && (
        <>
          <div className="schedule__day-start">
            <TimeField
              value={startTime}
              format={"HH:mm"}
              minutesStep={30}
              onChange={(newValue) => handleTimeChange(newValue, setStartTime)}
              disabled={!scheduleIsEditable()}
            />
          </div>

          <div className="schedule__day-lunch">
            <div className="schedule__lunch-pickers">
              <TimeField
                value={lunchStartTime}
                format={"HH:mm"}
                minutesStep={30}
                onChange={(newValue) => handleTimeChange(newValue, setLunchStartTime)}
                disabled={!scheduleIsEditable()}
              />
              -
              <TimeField
                value={lunchEndTime}
                format={"HH:mm"}
                minutesStep={30}
                onChange={(newValue) => handleTimeChange(newValue, setLunchEndTime)}
                disabled={!scheduleIsEditable()}
              />
            </div>
          </div>

          <div className="schedule__day-end">
            <TimeField
              value={endWorkTime}
              format={"HH:mm"}
              minutesStep={30}
              onChange={(newValue) => handleTimeChange(newValue, setEndWorkTime)}
              disabled={!scheduleIsEditable()}
            />
          </div>

          <div className="schedule__day-duration">
            <p>{calculateDuration()}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ScheduleDay;
