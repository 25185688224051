import React, { useEffect, useState } from "react";
import "../../../default_styles/tb-colors.css";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Api from "../../../../Api";
import UsersData from "../lib/UsersData";
import GetStringRole from "../../../../lib/GetStringRole";
import lsKeys from "../../../default_values/defaultKeys";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../../stores/profileStore";
import { useNotificationStore } from "../../../../stores/notificationStore";

const ShowUser = ({
  user,
  onEdit,
  closeShow,
  teams,
  userFormState,
  deleteUserFunction,
  users,
  activateUserFunction,
  deactivatedSelectUsers,
  tab,
  setTab,
  copyCalendar,
}) => {
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const { t } = useTranslation();
  const [notificationSetting, setNotificationSetting] = useState(user.notification_setting);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const role = localStorage.getItem(lsKeys.ROLE);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const isBlockedEditing = user.role === "admin" && role === "planner";

  useEffect(() => {
    Api.Integrations.Holidays.get().then((res) => {
      if (res.data.length > 0) {
        const holiday = res.data.filter((holiday) => holiday.users.map((u) => u.id).includes(user.id))[0];

        if (holiday) {
          setSelectedHoliday(holiday);
        }
      }
    });
  }, []);

  const reinvite = () => {
    Api.Users.reinvite(user.id).then(() => {
      setInAppNotification("User was reinvited");
    });
  };

  return (
    <Dialog
      className="form-show"
      onClose={closeShow}
      open={userFormState === "show"}
      classes={{ paper: "user-form-dialog-paper" }}
    >
      <DialogTitle>
        {tab === "general" ? (
          <p>{t("users.user_info")}</p>
        ) : (
          <p>{`${t("planning.filter.user")}: ${user.first_name} ${user.last_name[0]}`}</p>
        )}
        <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeShow} />
      </DialogTitle>

      <DialogContent>
        <UsersData
          viewType={"show"}
          firstName={user.first_name}
          lastName={user.last_name}
          email={user.email}
          phone={user.phone_number}
          billable={user.billable ? t("users.billable") : t("dashboard.non_billable")}
          role={GetStringRole(user)}
          timeZone={user.time_zone}
          userTeams={teams}
          teams={teams}
          tags={user.tags}
          user={user}
          users={users}
          schedule={user.schedule}
          startDate={user.start_date}
          endDate={user.end_date}
          tab={tab}
          setTab={setTab}
          notificationSetting={notificationSetting}
          setNotificationSetting={setNotificationSetting}
          selectedHoliday={selectedHoliday}
          notes={user.notes}
        />
      </DialogContent>

      <DialogActions style={{ padding: "0px 40px 40px 40px" }}>
        {deactivatedSelectUsers === "Deactivated" && (
          <TeambookIcon
            id="reactivateUserFormButton"
            className="users-page__user-button"
            onClick={() => activateUserFunction(user)}
            color="alternative_default"
            tooltipTitle={t("users.reactivate")}
            name={icons.ADD_USER}
          />
        )}

        {user.invited && (
          <TeambookIcon
            id="reinviteUserFormButton"
            name={icons.REINVITE}
            onClick={() => {
              reinvite();
            }}
            color="alternative_default"
            tooltipTitle={t("users.reinvite")}
          />
        )}

        {!isBlockedEditing && currentUser.id !== user.id && (
          <TeambookIcon
            id="deleteUserFormButton"
            name={icons.TRASH}
            onClick={() => deleteUserFunction(user)}
            color="alternative_default"
            tooltipTitle={deactivatedSelectUsers === "Active" ? t("users.deactivate") : t("delete")}
          />
        )}

        <TeambookIcon
          id="copyCalendarPageButton"
          className="users-page__user-button"
          onClick={() => copyCalendar(user)}
          alt="Copy"
          name={icons.CALENDAR}
          tooltipTitle={t("users.copy_calendar")}
          color="alternative_default"
        />

        {!isBlockedEditing && (
          <TeambookBlueButton
            text={t("users.edit_user")}
            disabled={deactivatedSelectUsers === "Deactivated"}
            onClick={onEdit}
            className="form__edit-button"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShowUser;
