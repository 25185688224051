import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBookingsEditingStore, useCopyBookingsStore, useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { Portal } from "./Portal";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const BookingRightClick = React.forwardRef(
  ({ deleteBookingsRequest, setBookingsEditing, closeEditWindow, changeTooltip, updateBookingsRequest }, ref) => {
    const [clearEditBookings] = useBookingsEditingStore((state) => [state.clearBookings], shallow);
    const [zoom] = useZoomStore((state) => [state.zoom], shallow);

    const [copyModeActive, setCopyModeActive, setCopyBookingId] = useCopyBookingsStore(
      (state) => [state.copyModeActive, state.setCopyModeActive, state.setCopyBookingId],
      shallow
    );

    const [open, setOpen] = useState(false);
    const [posX, setPosX] = useState(null);
    const [posY, setPosY] = useState(null);
    const [booking, setBooking] = useState({});
    const screenSize = document.body.clientWidth;
    const { t } = useTranslation();

    React.useImperativeHandle(ref, () => {
      return {
        open: open,
        setOpen: setOpen,
        setPosX: setPosX,
        setPosY: setPosY,
        setBooking: setBooking,
        copyModeActive: copyModeActive,
      };
    });

    const deleteBooking = () => {
      setOpen(false);
      changeTooltip({ open: false });
      deleteBookingsRequest([
        ...useBookingsEditingStore
          .getState()
          .bookingsEditing.filter((editingBooking) => editingBooking.id !== booking.id),
        booking,
      ]);
    };

    const markNotTentative = () => {
      setOpen(false);
      changeTooltip({ open: false });

      updateBookingsRequest(
        booking.duration / 60,
        false,
        booking.location,
        booking.start_time,
        booking.comment,
        booking.using_budget,
        [booking.id],
        { id: booking.project_id },
        booking.task_id,
        null
      );
    };

    const closeMenu = () => {
      setOpen(false);
    };

    const onCopyOptionClickHandler = () => {
      setCopyBookingId(booking.id);
      setOpen(false);
      closeEditWindow();
      clearEditBookings();
    };

    return (
      open &&
      !copyModeActive && (
        <>
          <Portal onClick={closeMenu} />

          <div
            className="planner__context-menu"
            style={{
              left: posX > screenSize - 130 ? posX - 130 : posX,
              top: posY,
            }}
          >
            {zoom !== 1 && useBookingsEditingStore.getState().bookingsEditing.length <= 1 && (
              <div className="planner__context-menu-option" onClick={onCopyOptionClickHandler}>
                <TeambookIcon style={{ width: 18, height: 18 }} name={icons.COPY} />
                <p>{t("my_week.copy")}</p>
              </div>
            )}

            {booking.tentative && (
              <div className="planner__context-menu-option" onClick={markNotTentative}>
                <TeambookIcon style={{ width: 18, height: 18 }} name={icons.CHECK_CIRCLE} />
                <p>{t("planning.remove_tentative")}</p>
              </div>
            )}

            <div className="planner__context-menu-option" onClick={deleteBooking}>
              <TeambookIcon style={{ width: 18, height: 18 }} name={icons.TRASH} />
              <p>{t("delete")}</p>
            </div>
          </div>
        </>
      )
    );
  }
);

export default BookingRightClick;
