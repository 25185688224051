import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { recalculateProjectCapacityArray } from "../lib/recalculateCapacityArray";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";

const ProjectsBlock = ({
  project,
  date,
  capacityReservations,
  user,
  updateUserReservations,
  jumpToProject,
  selectedViewType,
  isLastProject,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  useEffect(() => {
    setCalculatedDurations(recalculateProjectCapacityArray(date, capacityReservations, project, selectedViewType));
  }, [capacityReservations, date, project]);

  const updateReservation = (newValue, index) => {
    if (account.subscription.subscription_id === "Free" && account.subscription.projects_available === 10) {
      return;
    }

    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    Api.CapacityReservations.create({
      user_id: user.id,
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateUserReservations(index);
    });

    setCalculatedDurations(newCalculated);
  };

  const handleFocus = (event) => event.target.select();

  const userHasAccessForInput = () => {
    return fullAccessRoles.includes(role) || (role === "self_planner" && profile.id === user.id);
  };

  return (
    <div className="flex project-row">
      <div style={{ borderBottomLeftRadius: isLastProject ? 7 : 0 }} className="users-block__project-info-main">
        <div
          className="flex arrow-name"
          onClick={() => jumpToProject(project.id)}
          title={t("planning.capacity.go_to_project")}
        >
          <div style={{ background: project.color }} className="project-list__project-color" />

          <p>{project.name}</p>

          <TeambookIcon
            className="cursor-pointer"
            name={icons.ARROW_DOWN}
            color="darkblue"
            style={{ transform: "rotate(-90deg)", margin: "0px 40px 0px auto" }}
          />
        </div>
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => (
        <div
          style={{
            borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "",
            borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
          }}
          className="table-cell"
        >
          {userHasAccessForInput() ? (
            <input
              onChange={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
              onWheel={(e) => e.target.blur()}
              value={parseInt(calculatedDurations[i]) || 0}
              style={{
                width: "100%",
                // boxShadow: "-1px 0px 0px 0px var(--stroke)",
                borderLeft: "1px solid var(--stroke)",
                borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
              }}
              type={"number"}
              onFocus={handleFocus}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => e.preventDefault()}
            />
          ) : (
            <p>{parseInt(calculatedDurations[i])}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProjectsBlock;
