const accountSorting = (field, accounts, setAccounts) => {
  switch (field) {
    case "id":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.id - a.id;
        }),
      ]);
      return;
    case "name":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      ]);
      return;
    case "plan":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.plan_name?.toUpperCase(); // ignore upper and lowercase
          var nameB = b.plan_name?.toUpperCase(); // ignore upper and lowercase

          if (nameA === "TRIAL" || nameA === "FREE" || nameA === undefined) {
            return 2;
          } else if (nameB === "TRIAL" || nameB === "FREE" || nameB === undefined) {
            return -2;
          }

          console.log(nameA, nameB);

          if (nameA > nameB) {
            return 1;
          } else {
            return -1;
          }
        }),
      ]);
      return;
    case "language":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.language.toUpperCase(); // ignore upper and lowercase
          var nameB = b.language.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      ]);
      return;
    case "users":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.users_amount - a.users_amount;
        }),
      ]);
      return;
    case "projects":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.active_projects - a.active_projects;
        }),
      ]);
      return;
    case "clients":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.clients_amount - a.clients_amount;
        }),
      ]);
      return;
    case "bookings":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.bookings_amount - a.bookings_amount;
        }),
      ]);
      return;
    case "created_at":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.created_at.toUpperCase(); // ignore upper and lowercase
          var nameB = b.created_at.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        }),
      ]);
      return;

    case "last_seen":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.last_updated.toUpperCase(); // ignore upper and lowercase
          var nameB = b.last_updated.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        }),
      ]);
      return;

    case "active_days":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var activeDaysA = ((new Date(a.last_updated) - new Date(a.created_at)) / (1000 * 60 * 60 * 24)).toFixed(0); // ignore upper and lowercase
          var activeDaysB = ((new Date(b.last_updated) - new Date(b.created_at)) / (1000 * 60 * 60 * 24)).toFixed(0); // ignore upper and lowercase
          return activeDaysB - activeDaysA;
        }),
      ]);
      return;
    case "customer_name":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var nameA = a.customer_name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.customer_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      ]);
      return;
    case "transaction_date":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          var dateA = (new Date(a.transaction_date) / (1000 * 60 * 60 * 24)).toFixed(); // ignore upper and lowercase
          var dateB = (new Date(b.transaction_date) / (1000 * 60 * 60 * 24)).toFixed(); // ignore upper and lowercase
          return dateA - dateB;
        }),
      ]);
      return;
    case "amount_paid":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.amount_paid - a.amount_paid;
        }),
      ]);
      return;
    case "vat_amount":
      setAccounts((accounts) => [
        ...accounts.sort(function (a, b) {
          return b.vat_amount - a.vat_amount;
        }),
      ]);
      return;
  }
};

export default accountSorting;
