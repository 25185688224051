import React, { useEffect, useState } from "react";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import ShowProfile from "./ShowProfile";
import TimeZones from "../../../default_values/TimeZones";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { LanguagePicker } from "../../../default_components/LanguagePicker";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import CalendarInegrationDialog from "../../../my_week/modals/CalendarInegrationDialog";
import ChangeUserPhoto from "../../../users/sub_components/ChangeUserPhoto";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const EditProfile = () => {
  const [user, fetchProfile] = useProfileStore((state) => [state.profile, state.fetchProfile], shallow);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [timeZone, setTimeZone] = useState(user.time_zone);
  const [lang, setLang] = useState(user.language || "en");
  const [editing, setEditing] = useState(false);
  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem("tb-lang", lang);
    i18n.changeLanguage(lang || "en");
  }, [lang]);

  const changeLanguage = (lng) => {
    Api.Users.update(user.id, {
      settings: {
        language: lng,
      },
    })
      .then((response) => analyzeUpdateResponse(response))
      .catch((error) => console.log(error));
  };

  const analyzeUpdateResponse = (response) => {
    setLang(response.data.language);
    fetchProfile();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const submitValues = () => {
    Api.Users.update(user.id, {
      settings: {
        first_name: firstName,
        last_name: lastName,
        time_zone: timeZone,
      },
    })
      .then((response) => {
        closeErrorMessage();
        setInAppNotification("Your profile was successfully updated!");
        fetchProfile();
        setEditing(false);
      })
      .catch((error) => {
        analyzeUpdateError(error);
      });
  };

  const analyzeUpdateError = (error) => {
    console.log(error);
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
  };

  if (!editing) {
    return <ShowProfile setEditing={setEditing} />;
  }

  return (
    <div className="profile__general-info__form">
      <div className="profile__general-info__form__fields">
        <ChangeUserPhoto className="user-form__change-photo" user={user} />

        <div className="profile__info-edit">
          <div style={{ width: "40%" }}>
            <TeambookTextForm
              className="profile__input"
              fieldName={t("profile.general_info.first_name")}
              onChange={setFirstName}
              fieldValue={firstName}
              id={"first-name"}
              type={"text"}
            />
          </div>

          <div style={{ width: "40%" }}>
            <TeambookTextForm
              className="profile__input"
              fieldName={t("profile.general_info.last_name")}
              onChange={setLastName}
              fieldValue={lastName}
              id={"last-name"}
              type={"text"}
            />
          </div>

          {errorMessage !== null && errorField === "first_name" ? (
            <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
          ) : null}

          {errorMessage !== null && errorField === "last_name" ? (
            <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
          ) : null}

          <div style={{ width: "40%" }}>
            <TeambookReactSelect
              fieldName={t("profile.general_info.default_time_zone")}
              id="time-zone"
              options={TimeZones.timeZonesArray}
              onChange={(time_zone) => setTimeZone(time_zone.value)}
              value={TimeZones.timeZonesArray.find((time_zone) => time_zone.value === timeZone)}
              height={44}
              isSearchable={true}
              grayPlaceholder
            />
          </div>

          <CalendarInegrationDialog open={calendarIntegrationOpen} setOpen={setCalendarIntegrationOpen} user={user} />

          <div style={{ width: "40%" }}>
            <p style={{ marginBottom: 5, fontSize: 14, color: "var(--text-2)" }}>{t("profile.language.name")}</p>
            <LanguagePicker
              changeLanguage={changeLanguage}
              showCurrent={false}
              currentLang={lang}
              style={{ gap: 15 }}
            />
          </div>
        </div>
      </div>
      <WhiteTooltip title={t("users.copy_calendar")} placement="right">
        <div
          className="teambook-icon-frame"
          onClick={() => {
            if (user.calendar_token) {
              setCalendarIntegrationOpen(true);
              navigator.clipboard
                .writeText(Api.Calendar.link(user.calendar_token))
                .then(() => {
                  setInAppNotification(t("notifications.users.copy_calendar"));
                })
                .catch(() => {
                  alert("Something went wrong. Please try again later.");
                });
            } else {
              alert("Something went wrong. Please try again later.");
            }
          }}
          style={{
            position: "absolute",
            right: 39,
            top: 40,
            margin: "0 auto",
          }}
        >
          <TeambookIcon name={icons.CALENDAR} color="alternative_default" id="update" alt={"edit"} />
        </div>
      </WhiteTooltip>

      <div
        style={{
          position: "relative",
          right: 0,
          bottom: -50,
          gap: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TeambookOutlinedButton
          onClick={() => {
            setEditing(false);
            closeErrorMessage();
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setTimeZone(user.time_zone);
          }}
          text={t("cancel")}
        />
        <TeambookBlueButton onClick={submitValues} id={"save-changes"} variant="contained" text={t("save")} />
      </div>
    </div>
  );
};

export default EditProfile;
