import React, { useEffect, useRef, useState } from "react";
import "../../../default_styles/tb-colors.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import Api from "../../../../Api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import "moment/locale/de";
import "moment/locale/fr";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import TeambookColorPicker from "../../../default_components/TeambookColorPicker";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ClientCreationDialog } from "../Client/ClientCreationDialog";
import { managerOptions } from "../lib/managerOptions";
import { statusOptions } from "../lib/statusOptions";
import { dateFormat } from "../../../default_values/defaultDateFormats";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { DateTime } from "luxon";
import { useDataChange } from "../../../../lib/ussDataChange";
import WarningModal from "../../../default_components/WarningModal";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import ProjectAdvancedTab from "../default_components/ProjectAdvancedTab";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import MuiDatePicker from "../../../default_components/MuiDatePicker";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "../../../../stores/notificationStore";

const EditProject = ({
  project,
  getProjects,
  closeEditing,
  setChosenProject,
  clients,
  closeShow,
  page,
  setAllClients,
  users,
  tab,
  setTab,
}) => {
  const { t } = useTranslation();

  const kindOptions = [
    { value: "billable", name: t("projects.billable") },
    { value: "non_billable", name: t("projects.non_billable") },
  ];

  const projectTabs = [{ value: "general" }, { value: "tasks" }];
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState(project.name);
  const [code, setCode] = useState(project.code);
  const [client, setClient] = useState(clients.find((client) => client.id === project.client_id));
  const [kind, setKind] = useState(kindOptions.find((client) => client.value === project.kind));
  const [estimated, setEstimated] = useState(project.estimated);
  const [estimatedManDays, setEstimatedManDays] = useState(project.estimated / 8);
  const [color, setColor] = useState(project.color);
  const [startDate, setStartDate] = useState(DateTime.fromISO(project.start_date));
  const [endDate, setEndDate] = useState(DateTime.fromISO(project.end_date));
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [changeColorsOpened, setChangeColorsOpened] = useState(false);

  const [defineDates, setDefineDates] = useState({
    startDate: project.start_date !== null,
    endDate: project.end_date !== null,
  });
  const [clientCreationDelaogOpened, setClientCreationDelaogOpened] = useState(false);
  const [manager, setManager] = useState(managerOptions(users).find((opt) => opt.value === project.manager_id));
  const [status, setStatus] = useState(statusOptions(t).find((opt) => opt.value === project.status));
  const [businessUnit, setBusinessUnit] = useState(project.business_unit);
  const [warningOpened, setWarningOpened] = useState(false);
  const [notes, setNotes] = useState(project.notes || "<p></p>");
  const [selectedTasks, setSelectedTasks] = useState(project.tasks.map((t) => ({ value: t.id, name: t.name })));
  const [projectTasks, setProjectTasks] = useState(project.tasks);
  const [customFields, setCustomFields] = useState(JSON.parse(project.custom_fields || "{}"));

  const editorRef = useRef();
  const anchorRef = React.useRef(null);

  const isChanged = useDataChange(
    {
      name,
      code,
      client_id: client?.id || null,
      kind: kindOptions.find((client) => client.value === project.kind).value,
      start_date: startDate?.toISODate(),
      end_date: endDate?.toISODate(),
      manager_id: manager?.value || null,
      status: status?.value || null,
      business_unit: businessUnit || null,
      estimated: estimated || null,
      color: color,
      notes: notes,
      tasks: selectedTasks,
    },
    {
      ...project,
      notes: project.notes || "<p></p>",
      tasks: project.tasks.map((t) => ({ value: t.id, name: t.name })),
    }
  );

  useEffect(() => {
    if (account) {
      moment.updateLocale("en", {
        week: {
          dow: account.first_week_day === "Monday" ? 1 : 0,
        },
      });
    }
  }, [account]);

  const closeWarningModal = () => {
    setWarningOpened(false);
  };

  const closeEditWindow = (handleChanges = true) => {
    if (isChanged && handleChanges) {
      setWarningOpened(true);
    } else {
      closeEditing();
      closeShow();
    }
    setTab(projectTabs[0]);
  };

  const createClient = (name) => {
    Api.Clients.create({
      name: name,
      active: true,
      phone_number: "",
      email: "",
      notes: "",
    }).then((response) => {
      setAllClients([...clients, response.data]);
      setClient(response.data);
    });
  };

  const submitForm = () => {
    if (startDate > endDate && endDate) {
      analyzeEditProjectError({
        response: {
          data: {
            error: {
              message: t("projects.incorrect_date_format"),
              field: "end_date",
            },
          },
        },
      });
      return;
    }

    Api.Projects.update(project.id, {
      name: name,
      code: code,
      client_id: client?.id || null,
      kind: kind.value,
      estimated: estimated,
      color: color,
      icon_id: project.icon_i,
      manager_id: manager?.value,
      status: status?.value,
      business_unit: businessUnit,
      start_date: startDate?.toISODate() || "",
      end_date: endDate?.toISODate() || "",
      notes: editorRef?.current?.getComment() === "<p></p>" ? null : editorRef?.current?.getComment(),
      tasks: selectedTasks.map((t) => t.value),
      custom_fields: JSON.stringify(customFields),
    })
      .then((response) => {
        getProjects(response.data);
        setChosenProject(response.data);
        closeEditing();
        setInAppNotification(t("projects.updated_successfully"));
      })
      .catch((error) => {
        setTab(projectTabs[0]);
        analyzeEditProjectError(error);
      });
  };

  const analyzeEditProjectError = (error) => {
    console.log(error);
    setErrorMessage(error.response.data.error.message);

    setErrorField(error.response.data.error.field);
    closeWarningModal();
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
    setErrorField(null);
  };

  const changeDefineDatesState = (field) => {
    let newState = !defineDates[field];

    if (newState) {
      if (field === "startDate") {
        setStartDate((pr) => DateTime.now().plus({ month: 1 }).startOf("month").startOf("day").plus({ hours: 12 }));
      } else {
        setEndDate(DateTime.now().plus({ month: 1 }).endOf("month").startOf("day").plus({ hours: 12 }));
      }
    } else {
      if (field === "startDate") {
        setStartDate(null);
      } else {
        setEndDate(null);
      }
    }
    let newDefinedState = { ...defineDates };

    newDefinedState[field] = !defineDates[field];

    setDefineDates(newDefinedState);
  };

  return (
    <Dialog open={page === "edit"} onClose={closeEditWindow} className="form-edit">
      <DialogTitle>
        {tab.value === "general" ? (
          <p>{t("projects.edit_project")}</p>
        ) : (
          <p>{`${t("planning.filter.project")}: ${project.code}`}</p>
        )}
        <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeEditWindow} />
      </DialogTitle>

      <DialogContent
        style={{
          flexDirection: "column",
          padding: "0px 40px 0px 40px",
        }}
      >
        <div style={{ width: "100%" }} className="flex users-data__tabs">
          <div
            className={`users-data__tab ${tab.value === "general" && "active"}`}
            onClick={() => {
              setTab(projectTabs[0]);
            }}
          >
            <TeambookIcon color={tab.value === "general" ? "alternative_default" : "default"} name={icons.PROJECT} />
            <p>{t("users.general_info")}</p>
          </div>

          <div
            className={`users-data__tab ${tab.value === "tasks" && "active"}`}
            onClick={() => setTab(projectTabs[1])}
          >
            <TeambookIcon color={tab.value === "tasks" ? "alternative_default" : "default"} name={icons.ADVANCED} />
            <p>{t("planning.filter.advanced")}</p>
          </div>
        </div>
        {tab.value === "general" ? (
          <div style={{ display: "flex", gap: 20 }}>
            <div className="project-form__left-side">
              <WhiteTooltip title={t("projects.change_color")}>
                <div
                  className="project-form__project-color"
                  style={{ backgroundColor: color, cursor: "pointer" }}
                  onClick={() => setChangeColorsOpened(true)}
                  ref={anchorRef}
                />
              </WhiteTooltip>
              <TeambookColorPicker
                color={color}
                setColor={setColor}
                changeColorsOpened={changeColorsOpened}
                setChangeColorsOpened={setChangeColorsOpened}
                anchorRef={anchorRef}
              />
            </div>
            <div className="project-form__right-side">
              <div className="project-form__row">
                <div className="project-form__field">
                  <p className="project-form__field-name">{`${t("projects.name")} *`}</p>
                  <div className="project-form__field-value">
                    <TeambookTextForm
                      id="editProjectName"
                      className="user-form__field-input"
                      fieldValue={name}
                      onChange={(text) =>
                        setName(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")
                      }
                    />
                  </div>
                </div>
                <div className="project-form__field">
                  <p className="project-form__field-name">{`${t("projects.short_name")} *`}</p>
                  <div className="project-form__field-value">
                    <TeambookTextForm
                      id="editProjectShortName"
                      className="user-form__field-input"
                      fieldValue={code}
                      onChange={(text) =>
                        setCode(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")
                      }
                    />
                  </div>
                </div>
              </div>

              {errorMessage !== null && (errorField === "name" || errorField === "code") ? (
                <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
              ) : null}

              <div className="project-form__row">
                <div className="project-form__field">
                  <p className="project-form__field-name">{t("projects.client")}</p>
                  <div className="project-form__field-value">
                    <TeambookReactSelect
                      menuPortalTarget={document.body}
                      id="tags-outlined"
                      options={clients}
                      getOptionLabel={(option) => (option.name !== undefined ? `${option.name}` : `${option.label}`)}
                      isValidNewOption={(option) =>
                        option.length >= 2 &&
                        option.length <= 30 &&
                        !clients.find(({ name }) => name.toLowerCase() === option.toLowerCase().replace(/\s+/g, ""))
                      }
                      getOptionValue={({ id }) => id}
                      value={client}
                      onCreateOption={createClient}
                      defaultValue={client}
                      filterSelectedOptions
                      onChange={(client) => setClient(client)}
                      isSearchable={true}
                      formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                      grayPlaceholder
                    />
                  </div>
                </div>
                <div className="project-form__field">
                  <p className="project-form__field-name">{t("users.analytics")}</p>
                  <div className="project-form__field-value">
                    <TeambookReactSelect
                      options={kindOptions}
                      onChange={(client) => setKind(client)}
                      value={kind}
                      getOptionLabel={({ name }) => name}
                      grayPlaceholder
                    />
                  </div>
                </div>
              </div>

              <div className="project-form__row">
                <div className="project-form__field">
                  <p className="project-form__field-name">{t("projects.estimated")}</p>

                  <div className="project-form__field-value flex">
                    <TeambookTextForm
                      className="user-form__field-input estimated-hours"
                      fieldValue={estimated || ""}
                      onChange={(text) => {
                        setEstimated(text);
                        setEstimatedManDays(Math.round(text / 8));
                      }}
                      placeholder={800}
                      type={"number"}
                    />

                    <p className="estimated-hours__text">h</p>

                    <TeambookTextForm
                      className="user-form__field-input estimated-hours"
                      fieldValue={estimatedManDays || ""}
                      onChange={(text) => {
                        setEstimatedManDays(text);
                        setEstimated(text * 8);
                      }}
                      placeholder={100}
                      type={"number"}
                    />

                    <p className="estimated-hours__text">md</p>
                  </div>
                </div>

                <div className="project-form__field">
                  <p className="project-form__field-name">{t("projects.manager")}</p>
                  <div className="project-form__field-value">
                    <TeambookReactSelect
                      options={managerOptions(users)}
                      onChange={(user) => setManager(user)}
                      value={manager}
                      getOptionLabel={({ name }) => name}
                      isSearchable={true}
                      grayPlaceholder
                    />
                  </div>
                </div>
              </div>

              <div className="project-form__row">
                <div className="project-form__field">
                  <p className="project-form__field-name">{t("projects.status")}</p>
                  <div className="project-form__field-value">
                    <TeambookReactSelect
                      options={statusOptions(t)}
                      onChange={(status) => setStatus(status)}
                      value={status}
                      getOptionLabel={({ name }) => name}
                      grayPlaceholder
                    />
                  </div>
                </div>

                <div className="project-form__field">
                  <p className="project-form__field-name">{t("projects.business_unit")}</p>
                  <div className="project-form__field-value">
                    <TeambookTextForm
                      className="user-form__field-input"
                      fieldValue={businessUnit || ""}
                      onChange={(text) => setBusinessUnit(text)}
                    />
                  </div>
                </div>
              </div>

              <div className="project-form__row project-dates">
                <div className="project-form__field">
                  <TeambookCheckbox
                    className="tb-checkbox"
                    checked={defineDates.startDate}
                    onChange={() => changeDefineDatesState("startDate")}
                    color="primary"
                  />

                  <p
                    className="project-form__field-name"
                    onClick={() => changeDefineDatesState("startDate")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("projects.start_date")}
                  </p>

                  {defineDates.startDate && (
                    <div className="project-form__field-value">
                      <MuiDatePicker
                        variant="inline"
                        format={"d/MMM/yyyy"}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        sx={{ width: "148px" }}
                        className="mui-date-picker"
                        localeText={{
                          fieldDayPlaceholder: () => "dd",
                          fieldMonthPlaceholder: () => "mm",
                          fieldYearPlaceholder: () => "yyyy",
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className="project-form__field">
                  <TeambookCheckbox
                    className="tb-checkbox"
                    checked={defineDates.endDate}
                    onChange={() => changeDefineDatesState("endDate")}
                    color="primary"
                  />

                  <p
                    className="project-form__field-name"
                    onClick={() => changeDefineDatesState("endDate")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("projects.end_date")}
                  </p>

                  {defineDates.endDate && (
                    <div className="project-form__field-value">
                      <MuiDatePicker
                        format={"d/MMM/yyyy"}
                        value={endDate}
                        minDate={startDate || undefined}
                        onChange={(date) => setEndDate(date)}
                        sx={{ width: "148px" }}
                        className="mui-date-picker"
                        localeText={{
                          fieldDayPlaceholder: () => "dd",
                          fieldMonthPlaceholder: () => "mm",
                          fieldYearPlaceholder: () => "yyyy",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {errorMessage && errorField === "end_date" ? (
                <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
              ) : null}

              <div className="project-form__row">
                <div className="project-form__full-row-value">
                  <div className="project-form__field-value">
                    <RichTextEditor onChange={setNotes} ref={editorRef} value={project.notes || "<p></p>"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ProjectAdvancedTab
            tasks={selectedTasks}
            setTasks={setSelectedTasks}
            asyncCreate={true}
            project={project}
            setProjectTasks={setProjectTasks}
            customFields={customFields}
            setCustomFields={setCustomFields}
          />
        )}

        <ClientCreationDialog
          open={clientCreationDelaogOpened}
          setOpen={setClientCreationDelaogOpened}
          clients={clients}
          setAllClients={setAllClients}
          setClient={setClient}
        />
      </DialogContent>

      <DialogActions style={{ padding: "15px 40px 40px 40px" }}>
        <>
          <TeambookOutlinedButton onClick={closeEditWindow} className="form__edit-button" text={t("cancel")} />

          <TeambookBlueButton id="updateProject" onClick={submitForm} className="form__edit-button" text={t("save")} />
        </>
      </DialogActions>

      {warningOpened && (
        <WarningModal
          onNo={() => {
            closeEditWindow(false);
          }}
          closeModal={closeWarningModal}
        />
      )}
    </Dialog>
  );
};

export default EditProject;
